<template>
  <main class="navbar-offset-tablet salon-flow" :class="{ 'delete-img': deleteMode }">
    <PageNavigator
      :page-title="$t('salon-gallery.breadcrumbs-list-label')"
      :has-back="true"
      :button-back-link="'/salon'"
      class="d-flex justify-content-between align-items-center border-bottom px-1-5 py-0-8 position-fixed bg-white w-100"
    >
      <template v-slot:action>
        <div class="d-flex align-items-center">
          <div v-if="images.length" class="border mr-1-5 delete-btn">
            <div class="checkbox">
              <label class="mb-0">
                <input v-model="deleteMode" type="checkbox" />
                <i class="input-helper"></i>
              </label>
            </div>
          </div>

          <label v-if="!deleteMode" class="btn btn-primary px-4 py-1 mb-0">
            <label class="font-weight-medium poppins-font mb-0 upload-btn-text text-white" style="font-size: 14px;">
              {{ $t('common.update-photo-btn') }}
              <input
                id="files"
                ref="files"
                v-validate="'ext:jpeg,jpg,png'"
                type="file"
                accept="image/x-png,image/jpg,image/jpeg"
                :data-vv-as="$t('salon-gallery.photo')"
                multiple
                hidden=""
                @change="submitForm()"
              />
            </label>
          </label>

          <button
            v-else
            :disabled="seletedForDelete.length === 0"
            class="btn btn-primary px-4 py-1"
            @click="deleteSelected()"
          >
            {{ $t('common.delete-selected-btn') }}
          </button>
        </div>
      </template>
    </PageNavigator>

    <div v-if="images.length" class="px-1-5 pt-6-5 full-page-height">
      <div class="d-flex flex-wrap gallery-list">
        <div v-for="(image, key) in images" :key="key" class="m-0-8 position-relative">
          <img style="width:186px;height:120px;object-fit: cover;" :src="image.path" alt="" />
          <div class="position-absolute shadow-bg h-100 w-100">
            <div class="checkbox">
              <label class="mb-0">
                <input v-model="seletedForDelete" :value="image.id" type="checkbox" />
                <i class="input-helper"></i>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import PageNavigator from '@/components/PageNavigator'
import moment from 'moment/src/moment'

import { mapGetters, mapActions } from 'vuex'
import { compressedFile } from '@/_mixins/compressedFile.js'

export default {
  name: 'SalonGallery',
  components: {
    PageNavigator
  },
  mixins: [compressedFile],
  data() {
    return {
      dataLoaded: false,
      submitProcess: false,
      deleteMode: false,
      seletedForDelete: [],
      updateSuccessMessage: {
        type: this.$t('salon-gallery.alert.type_success'),
        messageI18Key: 'common.alerts.actions.updated'
      },
      updateErrorMessage: {
        type: this.$t('salon-gallery.alert.type_error'),
        messageI18Key: 'common.alerts.actions.global_error'
      },
      deleteLastInfoMessage: {
        type: 'info',
        messageI18Key: 'common.alerts.actions.delete_last_info'
      }
    }
  },
  computed: {
    ...mapGetters({
      accountInfo: 'user/getAccountInfo',
      images: 'gallery/getImages'
    })
  },
  mounted() {
    if (this.accountInfo.salon_id) {
      this.dataLoaded = false
      this.fetchImages(this.accountInfo.salon_id).then(response => {
        this.dataLoaded = true
      })
    }
  },
  methods: {
    ...mapActions({
      fetchImages: 'gallery/getAllBySalonId',
      updateImages: 'gallery/update',
      deleteSeletedImages: 'gallery/deleteSeleted',
      setSuccess: 'userMessages/setSuccess',
      setError: 'userMessages/setError'
    }),
    submitForm() {
      let countAllImages = this.$refs.files.files.length + this.images.length

      if (countAllImages > 10) {
        this.setError({
          type: 'error',
          message: this.$t('common.alerts.actions.max_count_gallery')
        })

        return
      }

      this.$validator.validateAll().then(result => {
        if (result) {
          let formData = new FormData()

          formData.append('salon_id', this.accountInfo.salon_id)

          for (var i = 0; i < this.$refs.files.files.length; i++) {
            this.compressed(this.$refs.files.files[i], 5, 1920)
              .then(file => {
                let imageFile = file

                formData.append('images[' + i + ']', imageFile)

                this.updateImages(formData)
                  .then(response => {
                    this.setSuccess(this.updateSuccessMessage)
                  })
                  .catch(error => {
                    this.setError(this.updateErrorMessage)
                  })
              })
              .catch(errors => console.log(errors))
          }
        } else {
          let error = this.$validator.errors.items[0]
          let message = error.msg

          if (error.rule === 'ext') {
            message = message + this.$t('common.alerts.actions.format_error')
          }

          this.setError({
            type: 'error',
            message: message
          })
        }
      })
    },
    confirmMessage() {
      const h = this.$createElement
      const messageVNode = h('div', { class: ['foobar'] }, [
        h('p', { class: ['h6 text-center mb-0 text-black pt-2-5 pb-1-5'] }, [this.$t('common.confirm.delete_title')])
      ])

      return this.$bvModal
        .msgBoxConfirm([messageVNode], {
          centered: true,
          size: 'md',
          footerClass: 'pt-0 pb-4 px-4 has-cusstom-btns',
          bodyClass: ' pb-4 px-4',
          cancelVariant: 'light',
          cancelTitle: `${this.$t('alert_message.cancel_title')}`
        })
        .then(value => {
          return value
        })
        .catch(error => {
          return error
        })
    },
    deleteProcess() {
      let payload = {
        salon_id: this.accountInfo.salon_id,
        images: this.seletedForDelete
      }

      this.deleteSeletedImages(payload)
        .then(response => {
          this.seletedForDelete = []
          this.setSuccess(this.updateSuccessMessage)
        })
        .catch(error => {
          if (error.message === "You can't delete last image") {
            this.setError(this.deleteLastInfoMessage)
          } else {
            this.setError(this.updateErrorMessage)
          }
        })
    },
    deleteSelected() {
      this.confirmMessage()
        .then(value => {
          if (value) {
            this.deleteProcess()

            this.deleteMode = false
          }
        })
        .catch(error => {})
    }
  }
}
</script>

<style lang="scss" scoped></style>
